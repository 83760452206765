/* eslint-disable import/no-unused-modules */
import React from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '@mui/system';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Button } from '../components/Button';
import ContactOffices from '../components/ContactOffices';
import Helmet from '../components/Helmet';
import Lottie from '../components/Lottie';
import { H2, P, PMedium } from '../components/Text';
import TransHTML from '../components/TransHTML';
import { PageLayout } from '../shared/layouts';
import withI18n from '../hocs/withI18n';
import { contactUsOptions } from '../modules/contact-us/ContactUs.helper';
import { FONT_BOLD, screenLessThan } from '../shared/styles';
import contactUsAnimation from '../../static/json/contact-us.json';

export const Head = withI18n(() => {
  return <Helmet pageName="contactUs" />;
});

const ContactUs = () => {
  const { t, navigate } = useI18next();

  const handleClick = (info) => {
    if (info.link) {
      navigate(info.link);
    } else if (info.isChat) {
      const chatIframe = document.getElementById('LeadboosterContainer');
      const chatButton =
        chatIframe.contentWindow.document.querySelector('button');
      if (chatButton) {
        chatButton.click();
      } else {
        console.log('Loading Chat. Please wait..');
      }
    }
  };

  return (
    <PageLayout>
      <Stack direction={['column', 'row']} alignItems="center">
        <ContactHeaderDetails>
          <H2 align="start" margin="0 0 1rem 0">
            <TransHTML contentKey="contact_us.title" />
          </H2>
          <PMedium>{t('contact_us.subtitle')}</PMedium>
        </ContactHeaderDetails>
        <Lottie src={contactUsAnimation} />
      </Stack>

      <ContactOptionsWrapper>
        {contactUsOptions.map((info, index) => (
          <ContactOptionsCard middleCard={index === 1} key={info.title}>
            <img src={info.icon} alt={info.title} />
            <PBold margin="20px 0 5px 0">{t(info.title)}</PBold>
            <P margin="0 0 25px 0">
              <TransHTML contentKey={info.description} />
            </P>
            <Button onClick={() => handleClick(info)}>{t(info.button)}</Button>
          </ContactOptionsCard>
        ))}
      </ContactOptionsWrapper>

      <ContactOffices />
    </PageLayout>
  );
};

const CardCSS = css`
  border: 1px solid #e7e8f3;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
`;

const ContactHeaderDetails = styled.div``;
const ContactOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const ContactOptionsCard = styled.div`
  ${CardCSS};
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 1rem;
  width: 25%;
  ${screenLessThan('1000px')} {
    width: 35%;
  }
  ${screenLessThan('650px')} {
    width: 80%;
  }
  img {
    height: 52px;
    width: 52px;
  }
  ${(props) =>
    props.middleCard &&
    css`
      margin-left: 2rem;
      margin-right: 2rem;
      ${screenLessThan('650px')} {
        margin-left: 0;
        margin-right: 0;
      }
    `};
  button {
    min-width: 160px;
  }
`;

const PBold = styled(PMedium)`
  ${FONT_BOLD}
`;

export default ContactUs;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
